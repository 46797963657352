import { useState } from 'react';
import { Box, Flex, Progress } from '@chakra-ui/react';
import shallow from 'zustand/shallow';
import type { BoxProps } from '@chakra-ui/react';
import { useQuizStore } from 'src/hooks/useQuiz';
import { backBgStyle, infoButtonStyle, progressBar } from './quizheaderstyles';
import { ReactComponent as BackIcon } from '../../assets/images/back.svg';
import { ReactComponent as InfoButton } from '../../assets/images/question-mark.svg';
import Header from '@components/Header';
import Link from '@components/Link';
import QuizHeaderButton from '@components/QuizHeaderButton';
import { PopupHelpQuiz } from '@components/PopupHelpQuiz';
import { sendEvent } from '@utils/analytics';
import { rem } from '@styles/textStyles';

type QuizHeaderProps = { title: string } & BoxProps;

const QuizHeader = ({ title, ...props }: QuizHeaderProps) => {
  const [helpPopupOpen, setHelpPopupOpen] = useState(false);
  const quizStore = useQuizStore(
    state => ({
      currentQuestion: state.currentQuestion,
      responses: state.responses,
      popResponse: state.popResponse,
      progress: state.progress
    }),
    shallow
  );

  const backHandler = () => {
    sendEvent('back');
    quizStore.popResponse();
  };

  return (
    <Box {...props} zIndex="3" position="relative">
      <Header
        containerStyle={{ flexWrap: 'wrap' }}
        titleStyle={{ w: 'full' }}
        leftControls={
          <>
            {quizStore.responses && quizStore.responses.length > 1 ? (
              <QuizHeaderButton onClick={backHandler} {...backBgStyle}>
                <BackIcon />
              </QuizHeaderButton>
            ) : (
              <Link href="/">
                <QuizHeaderButton {...backBgStyle}>
                  <BackIcon />
                </QuizHeaderButton>
              </Link>
            )}
          </>
        }
        title={title}
        progressbar={
          <Progress {...progressBar} value={quizStore.progress * 100} />
        }
        rightControls={
          <Flex gap={{ base: rem(8), md: rem(15) }} alignItems="center">
            <QuizHeaderButton
              onClick={() => setHelpPopupOpen(true)}
              boxShadow={{
                base: 'none',
                md: '0px 4px 10px rgba(0, 0, 0, 0.1)'
              }}
              {...infoButtonStyle}
            >
              <InfoButton />
            </QuizHeaderButton>
          </Flex>
        }
      />
      <PopupHelpQuiz
        isOpen={helpPopupOpen}
        onClose={() => setHelpPopupOpen(p => !p)}
      />
    </Box>
  );
};

export default QuizHeader;
