import { FC } from 'react';
import { MotionBox } from '../../components';
import { Spinner } from '@chakra-ui/react';
import { spinnerHolder, wrapper } from './styles';

interface ILoading {
  isLoading: boolean;
}

const Loading: FC<ILoading> = ({ isLoading, ...restProps }) => {
  return (
    <MotionBox {...wrapper(isLoading)} {...restProps}>
      <MotionBox {...spinnerHolder}>
        <Spinner size="lg" color="white" />
      </MotionBox>
    </MotionBox>
  );
};

export default Loading;
