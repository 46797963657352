import { Box, Flex, Heading, Show, Text } from '@chakra-ui/react';
import Image from '@components/Image';
import { PageContext } from '@components/Page';
import Popup, { PopupProps } from '@components/Popup';
import { popupTitle } from '@components/Popup/Popupstyles';
import React, { useContext, useEffect } from 'react';
import helpPopupImage from '../../assets/images/help-popup.jpg';
import { sendEvent } from '@utils/analytics';

type PopupHelpQuizProps = PopupProps;
export const PopupHelpQuiz = (props: PopupHelpQuizProps) => {
  const { copy } = useContext(PageContext);

  useEffect(() => sendEvent('help_open'), []);

  return (
    <Popup
      {...props}
      maxW="55rem"
      py={{ md: '5.5rem', portrait: '2.5rem' }}
      pl={{ md: '5.5rem' }}
    >
      <Flex gap="70px">
        <Box flex="3">
          <Text
            color="green"
            fontSize={{ md: 'md', portrait: 'xxs' }}
            mb={{ md: 2, portrait: 1 }}
          >
            {copy('quiz.help.kicker')}
          </Text>
          <Heading as="h3" {...popupTitle}>
            {copy('quiz.help.title')}
          </Heading>
          <Show below="md">
            <Box mx="-1.333rem">
              <Image src={helpPopupImage} alt="" role="presentation" />
            </Box>
          </Show>
          <Box
            textAlign={{ md: 'left', portrait: 'center' }}
            fontSize={{ md: 'lg' }}
            color="gray.800"
          >
            <Text my="1em">{copy('quiz.help.body')}</Text>
          </Box>
        </Box>
        <Show above="md">
          <Box flex="2.25">
            <Image src={helpPopupImage} alt="" role="presentation" />
          </Box>
        </Show>
      </Flex>
    </Popup>
  );
};
