import type { ReactNode } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import type { StackProps } from '@chakra-ui/react';

import Footer from '../Footer';
import Cookies from '@components/Cookies';

type LayoutProps = {
  hasFooter?: boolean;
  children: ReactNode;
  hideOverflow?: boolean;
  useMaxHeight?: boolean;
} & StackProps;

const Layout = ({
  children,
  hasFooter = true,
  hideOverflow = false,
  useMaxHeight = false,
  ...props
}: LayoutProps) => {
  return (
    <>
      <VStack
        minH="var(--fold, 100vh)"
        background={useMaxHeight ? '#000' : ''}
        {...props}
      >
        <Box
          flexGrow="1"
          w="full"
          overflow={hideOverflow ? 'hidden' : 'initial'}
          background={useMaxHeight ? '#fff' : ''}
        >
          {children}
        </Box>
        {hasFooter && <Footer />}
      </VStack>
      <Cookies />
    </>
  );
};

export default Layout;
