export const checkedboxW = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 1,
  opacity: 0,
  cursor: 'pointer'
};
