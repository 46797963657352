import type { BoxProps, FlexProps, HeadingProps } from '@chakra-ui/react';
import type { IconProps } from '@chakra-ui/icons';

export const popOverlay: FlexProps = {
  pos: 'fixed' as const,
  top: 0,
  left: 0,
  w: '100%',
  h: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 250
};
export const innerOverlay: BoxProps = {
  pos: 'absolute' as const,
  top: 0,
  left: 0,
  w: '100%',
  h: '100%',
  bg: 'rgba(0, 0, 0, 0.5)'
};

export const popContainer: BoxProps = {
  pos: 'relative',
  w: '90%',
  maxW: '37.5rem',
  maxH: 'calc(100 * var(--vh, 1vh) - 2.666rem)',
  overflow: 'auto',
  bg: 'color.white',
  color: 'color.black',
  px: { md: '2.5rem', portrait: '1.333rem' },
  py: { md: '4.375rem', portrait: '2.5rem' },
  borderRadius: '1.25rem'
};

export const closeBtn: FlexProps = {
  pos: 'absolute' as const,
  w: '2.188rem',
  h: '2.188rem',
  bg: 'color.black',
  right: { md: '1.563rem', portrait: '0.938rem' },
  top: { md: '1.563rem', portrait: '0.938rem' },
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  cursor: 'pointer',
  _hover: {
    transform: 'rotate(360deg)',
    transition: 'all .7s ease-in-out 0s'
  }
};

export const closeIcon: IconProps = {
  color: 'color.white',
  w: '0.75rem',
  h: '0.75rem'
};

export const popupTitle: HeadingProps = {
  fontSize: { md: '3xl', portrait: '2.313rem' },
  lineHeight: '3.125rem',
  textTransform: 'uppercase' as const,
  mb: { md: '1.875rem', portrait: '1rem' }
};
