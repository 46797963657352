import { FC, useContext } from 'react';
import { Button, VStack } from '@chakra-ui/react';

import { Text } from '../../components';
import { PageContext } from '@components/Page';
import Link from 'next/link';

const Footer: FC = () => {
  const { copy, cookies, externalLink } = useContext(PageContext);

  return (
    <VStack
      px={8}
      py={12}
      bg="black"
      alignItems="flex-start"
      gap={1}
      width="full"
    >
      <Button
        onClick={() => cookies.setShowPopup(true)}
        color="white"
        variant="link"
        fontSize="sm"
        fontWeight="normal"
      >
        {copy('generic.footer.cookie_settings')}
      </Button>
      <a href={externalLink('privacyPolicy')} rel="noreferrer" target="_blank">
        <Text
          text={copy('generic.footer.privacy_policy')}
          textStyle="caption"
          color="white"
        />
      </a>
      <a href={externalLink('termsOfUse')} rel="noreferrer" target="_blank">
        <Text
          text={copy('generic.footer.terms_and_conditions')}
          textStyle="caption"
          color="white"
        />
      </a>
      <Text
        fontSize="xs"
        color="gray.600"
        text={copy('generic.footer.copyright')}
      />
    </VStack>
  );
};

export default Footer;
