import { FC } from 'react';
import { Text as ChakraText, TextProps } from '@chakra-ui/react';

interface IText extends TextProps {
  text: string;
  fitty?: boolean;
  outlines?: boolean;
}

const acceptedTags: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];
const acceptedItalicTags: string[] = [
  'h1Italic',
  'h2Italic',
  'h3Italic',
  'h4Italic',
  'h5Italic',
  'h6Italic'
];

const Text: FC<IText> = ({ text, ...restProps }) => {
  const { textStyle } = restProps;

  let tag: any = 'p';
  if (acceptedTags.includes(textStyle as string)) {
    tag = textStyle;
  }
  if (
    typeof textStyle === 'string' &&
    acceptedItalicTags.includes(textStyle as string)
  ) {
    tag = textStyle.split('Italic')[0] || 'p';
  }

  return (
    <ChakraText
      dangerouslySetInnerHTML={{ __html: text }}
      {...restProps}
      as={tag as any}
    />
  );
};

export default Text;
