import { useContext } from 'react';
import NextLink, { LinkProps } from 'next/link';
import type { UrlObject } from 'url';

import { PageContext } from '@components/Page';
import type { Locale } from '@utils/api';

export const link = (
  href: LinkProps['href'],
  locale: Locale,
  region?: string
) => {
  return typeof href === 'string'
    ? {
        pathname: `/[locale]${href}`,
        query: {
          locale,
          region
        }
      }
    : ({
        ...href,
        pathname: `/[locale]${href.pathname}`,
        query:
          typeof href.query === 'string'
            ? href.query
            : {
                ...href.query,
                locale,
                region
              }
      } as UrlObject);
};

type props = LinkProps & {
  children: React.ReactNode;
};

const Link = ({ href, children, ...props }: props) => {
  const { locale, region } = useContext(PageContext);

  if (typeof href === 'string' && href.startsWith('http')) {
    return (
      <NextLink {...props} href={href}>
        {children}
      </NextLink>
    );
  }

  const safeHref = link(href, locale, region);

  return (
    <NextLink {...props} href={safeHref}>
      {children}
    </NextLink>
  );
};

export default Link;
