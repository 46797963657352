import type { ButtonProps, ProgressProps } from '@chakra-ui/react';
import { rem } from '@styles/textStyles';

export const progressBar: ProgressProps = {
  w: 'full',
  maxWidth: {
    base: rem(184),
    md: rem(375)
  }
};

export const infoButtonStyle: ButtonProps = {
  width: { base: rem(30), md: rem(56) },
  height: { base: rem(30), md: rem(56) },
  minWidth: { base: rem(30), md: rem(40) }
};
export const backBgStyle: ButtonProps = {
  ...infoButtonStyle,
  filter: 'invert(1)',
  _hover: {
    filter: 'invert(0)'
  }
};
