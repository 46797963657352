import { FC, useContext, useState } from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import {
  container,
  mainbox,
  headingh2,
  cookiesParagraph,
  innerContainer,
  ButtonWrapper
} from './Cookiesettingsstyles';
import NikeButton from '../Button';
import { PageContext } from '@components/Page';
import { CookieItem } from './CookieItem';

interface BaseProps {
  onSave: () => void;
}

export type CookiesettingsProps = BaseProps;

export const Cookiesettings: FC<CookiesettingsProps> = ({
  onSave,
  ...props
}) => {
  const { copy, cookies, externalLink } = useContext(PageContext);
  const [analytics, setAnalytics] = useState<boolean>(true);

  const cookieSettingsContent = (
    <Flex {...props} {...container}>
      <Box {...mainbox}>
        <Box {...innerContainer}>
          <Heading {...headingh2}>
            {copy('generic.cookies.settings.title')}
          </Heading>
          <Text {...cookiesParagraph}>
            {copy('generic.cookies.modal.copy1')}
          </Text>
          <CookieItem
            variant="checkboxOpacity"
            isChecked
            titleKey="generic.cookies.functional.title"
            bodyKey1="generic.cookies.functional.copy[0]"
            bodyKey2="generic.cookies.functional.copy[1]"
          />
          <CookieItem
            variant="checkboxBlack"
            isChecked={analytics}
            clickHandler={() => setAnalytics(!analytics)}
            titleKey="generic.cookies.analytics.title"
            bodyKey1="generic.cookies.analytics.copy"
            bodyKey2="generic.cookies.analytics.copy2"
          />
        </Box>
        <Box {...ButtonWrapper}>
          <NikeButton
            content={copy('generic.cookies.settings.accept')}
            onClick={() => {
              cookies.setAccepted('analytics', analytics);
              cookies.setAccepted('functional', true);
              onSave();
            }}
            variant="isBlackRadius"
          />
        </Box>
      </Box>
    </Flex>
  );

  return <Flex>{cookieSettingsContent}</Flex>;
};
