import { useEffect } from 'react';
import {
  Flex,
  Box,
  keyframes,
  usePrefersReducedMotion
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  popOverlay,
  popContainer,
  closeBtn,
  closeIcon,
  innerOverlay
} from './Popupstyles';
import type { FC } from 'react';
import type { BoxProps } from '@chakra-ui/react';

interface BaseProps {
  isIconvisible?: string;
  isOpen: boolean;
  onClose: () => void;
}

export type PopupProps = BaseProps & BoxProps;

export const Popup: FC<PopupProps> = ({
  children,
  isIconvisible,
  isOpen,
  onClose,
  ...props
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'initial';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isOpen]);

  if (!children) {
    return null;
  }

  const popGl = keyframes`
    0% { top: 100% } 100% { top: 0 }`;

  const popupanim = prefersReducedMotion ? undefined : `${popGl} .7s forwards`;

  return (
    isOpen && (
      <Flex {...popOverlay}>
        <Box {...innerOverlay} onClick={onClose}></Box>
        <Box {...popContainer} {...props} animation={popupanim}>
          <Flex
            as="span"
            {...closeBtn}
            sx={{ visibility: `${isIconvisible}` }}
            onClick={onClose}
          >
            <CloseIcon {...closeIcon} />
          </Flex>
          {children}
        </Box>
      </Flex>
    )
  );
};
