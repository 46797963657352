import type {
  BoxProps,
  FlexProps,
  HeadingProps,
  TextProps
} from '@chakra-ui/react';

export const container: FlexProps = {
  w: '100%',
  h: '100%',
  bg: 'color.cookiebg',
  justifyContent: 'center'
};

export const headingh2: HeadingProps = {
  as: 'h2',
  textStyle: 'h2',
  lineHeight: '2.53rem',
  fontSize: '2rem',
  mb: '1rem'
};

export const headingh3: HeadingProps = {
  as: 'h3',
  textStyle: 'h3',
  fontSize: '1.8rem',
  lineHeight: '1.1',
  transform: 'translateY(-0.188rem)',
  ml: '1.25rem',
  mb: '0'
};

export const mainbox: BoxProps = {
  w: '100%',
  maxW: '75rem',
  position: 'relative',
  css: {
    '-webkit-overflow-scrolling': 'touch'
  }
};

export const paragraph: TextProps = {
  fontSize: 'xs',
  lineHeight: 'md'
};

export const contentparagraph: TextProps = {
  mb: '1rem',
  pl: '2.813rem'
};
export const cookiesParagraph: TextProps = {
  fontSize: 'xs',
  lineHeight: 'md',
  color: '#ABABAB',
  mb: '1.3rem'
};

export const boxHeader: FlexProps = {
  w: '100%',
  h: '100%',
  pt: '0.5rem',
  bg: 'color.cookiebg',
  justifyContent: 'space-Between',
  alignItems: 'center'
};
export const boxWrapper: BoxProps = {
  borderBottom: '1px solid #ABABAB',
  overflow: 'hidden',
  _last: {
    borderBottom: 'none',
    mb: {
      base: '4rem',
      md: '1.5rem'
    }
  }
};
export const ButtonWrapper: BoxProps = {
  position: 'relative',
  _after: {
    content: '""',
    height: '5rem',
    width: '100%',
    position: 'absolute',
    top: '-76px',
    left: '0',
    background:
      'linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 100%)',
    display: {
      base: 'block',
      md: 'none'
    },
    pointerEvents: 'none'
  }
};

export const innerContainer: BoxProps = {
  overflowY: {
    base: 'auto',
    md: 'unset'
  },
  marginTop: '1rem',
  maxHeight: {
    base: '354px',
    md: 'unset'
  },
  paddingRight: {
    base: '14px',
    md: '0'
  }
};

export const pointer = {
  cursor: 'pointer'
};
