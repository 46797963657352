import type { BoxProps, FlexProps } from '@chakra-ui/react';
import { rem } from '@styles/textStyles';

export const headerWrapper: FlexProps = {
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  zIndex: 1
};
export const titleWrapper: BoxProps = {
  textAlign: {
    base: 'left',
    md: 'center'
  },
  maxWidth: {
    base: '70%',
    md: '100%'
  },
  mx: 1,
  mt: {
    base: rem(25),
    md: rem(55)
  },
  color: 'color.black',
  lineHeight: {
    base: rem(26),
    md: rem(39)
  },
  fontSize: {
    base: rem(22),
    md: rem(32)
  },
  fontWeight: '700'
};
