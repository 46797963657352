import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { headerWrapper, titleWrapper } from './styles';

type HeaderProps = {
  title: string;
  leftControls?: React.ReactElement;
  rightControls?: React.ReactElement;
  progressbar?: React.ReactElement;
  containerStyle: FlexProps;
  titleStyle: BoxProps;
  showTooltip?: boolean;
};

const Header = ({
  title,
  leftControls,
  rightControls,
  containerStyle,
  titleStyle,
  progressbar,
  showTooltip
}: HeaderProps) => {
  const wrapperStyle = { ...headerWrapper, ...containerStyle };
  const titleWrapperStyle = { ...titleWrapper, ...titleStyle };
  return (
    <Flex
      {...wrapperStyle}
      zIndex={showTooltip ? undefined : wrapperStyle.zIndex}
    >
      {leftControls}
      {progressbar}
      {rightControls}
      <Box {...titleWrapperStyle} sx={{ hyphens: 'auto' }}>
        {title}
      </Box>
    </Flex>
  );
};

export default Header;
