// @ts-nocheck
import { FC } from 'react';
import { Flex, Box, StylesProvider } from '@chakra-ui/react';
import { checkedboxW } from './Checkboxstyles';
import { CheckboxVariants } from './variants';
import { ReactComponent as CheckedIcon } from '../../assets/images/checkedIcon.svg';
export interface BaseProps {
  onClick?: () => void;
  variant: string;
  isChecked?: boolean;
}

export type CheckboxProps = BaseProps;

export const Checkbox: FC<CheckboxProps> = ({
  onClick,
  variant,
  isChecked
}) => {
  function CheckwrapStyle(props: {
    [x: string]: unknown;
    variant: string;
    children: React.ReactElement;
  }) {
    const { variant, children, ...rest } = props;
    const styles = CheckboxVariants.variants[variant]?.checkwrap;
    const baseStyle = CheckboxVariants.baseStyle['checkwrap'];
    return (
      <Box __css={{ ...baseStyle, ...styles }} {...rest}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Box>
    );
  }

  function CheckboxItem(props) {
    const baseStyles = CheckboxVariants.baseStyle;

    if (isChecked) {
      return (
        <Box {...baseStyles.checkitem} onClick={onClick}>
          <input type="checkbox" style={checkedboxW} checked readOnly />
          <CheckedIcon width={18} height={18} />
        </Box>
      );
    } else {
      return (
        <Box onClick={onClick}>
          <input type="checkbox" style={checkedboxW} readOnly />
        </Box>
      );
    }
  }

  return (
    <CheckwrapStyle variant={variant}>
      <CheckboxItem />
    </CheckwrapStyle>
  );
};
