const wrapperVariants = {
  active: {
    opacity: 1,
    transition: {
      opacity: {
        duration: 0.2
      },
      delayChildren: 0.3
    }
  },
  inactive: {
    opacity: 0
  }
};

export const wrapper = (isLoading: boolean) => ({
  initial: 'inactive',
  animate: isLoading ? 'active' : 'inactive',
  variants: wrapperVariants,
  position: 'fixed' as const,
  display: isLoading ? 'flex' : 'none',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 200,
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0,0,0, 0.9)'
});

const spinnerVariants = {
  inactive: {
    scale: 0
  },
  active: {
    scale: 1
  }
};

export const spinnerHolder = {
  variants: spinnerVariants
};
