import { useContext } from 'react';

import Popup from '@components/Popup';
import Cookiesettings from '@components/Cookiesettings';
import { PageContext } from '@components/Page';
import type { PopupProps } from '@components/Popup';

export type CookiesProps = Omit<PopupProps, 'isOpen' | 'onClose'>;

export const Cookies = (props: CookiesProps) => {
  const { cookies } = useContext(PageContext);
  return (
    <Popup
      {...props}
      {...{ maxW: '31rem' }}
      isOpen={cookies.showPopup}
      onClose={() => cookies.setShowPopup(false)}
    >
      <Cookiesettings
        onSave={() => {
          cookies.setShowPopup(false);
        }}
      />
    </Popup>
  );
};
