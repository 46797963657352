import {
  Box,
  Collapse,
  Flex,
  Heading,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import Checkbox from '@components/Checkbox';
import { PageContext } from '@components/Page';
import React, { FC, useContext } from 'react';
import {
  boxHeader,
  contentparagraph,
  headingh3,
  boxWrapper,
  paragraph,
  pointer
} from './Cookiesettingsstyles';
import { ReactComponent as PlusIcon } from '../../assets/images/plusIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/iconCloseModal.svg';
type CheckBoxProp = {
  isChecked: boolean;
  variant: string;
  titleKey: string;
  bodyKey1: string;
  bodyKey2: string;
  clickHandler?: () => void;
};

export const CookieItem: FC<CheckBoxProp> = props => {
  const { copy } = useContext(PageContext);
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box {...boxWrapper}>
      <Flex {...boxHeader}>
        <Flex>
          <Checkbox
            variant={props.variant}
            isChecked={props.isChecked}
            onClick={props.clickHandler}
          />
          <Heading {...headingh3}>{copy(props.titleKey)}</Heading>
        </Flex>
        {isOpen ? (
          <CloseIcon width={20} height={20} onClick={onToggle} {...pointer} />
        ) : (
          <PlusIcon width={14} height={14} onClick={onToggle} {...pointer} />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Flex>
          <Text sx={{ ...paragraph, ...contentparagraph }}>
            {copy(props.bodyKey1)}
            {copy(props.bodyKey2)}
          </Text>
        </Flex>
      </Collapse>
    </Box>
  );
};
