import { forwardRef, Button } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';

export const customBtn: ButtonProps = {
  padding: 0,
  color: 'color.black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: {
    base: '2.5rem',
    md: '3.5rem'
  },
  height: {
    base: '2.5rem',
    md: '3.5rem'
  },
  borderRadius: '50%',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  transition: '0.3s all ease-in-out',
  _hover: {
    filter: 'invert(1)'
  }
};

const QuizHeaderButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return <Button ref={ref} {...customBtn} {...props} />;
});

export default QuizHeaderButton;
