import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { opacityUp } from '../../styles/defaultAnims';

// Checkbox's variants
export const CheckboxVariants: ComponentStyleConfig = {
  parts: ['checkwrap', 'checkitem'],
  baseStyle: {
    checkwrap: {
      pos: 'relative' as const,
      cursor: 'pointer',
      w: '1.5rem',
      h: '1.5rem',
      bg: 'transparent',
      border: '1px solid',
      borderColor: '#000',
      display: 'inline-block',
      borderRadius: 0
    },
    checkitem: {
      p: '2px'
    }
  },
  variants: {
    checkboxBlack: {
      checkwrap: {
        borderColor: '#000',
        flexShrink: 0
      },
      checkitem: {
        animation: `${opacityUp} .5s linear forwards`,
        _after: {
          borderColor: '#000'
        }
      }
    },
    checkboxBlackBack: {
      checkwrap: {
        bg: '#fff',
        borderColor: 'gray.400'
      },
      checkitem: {
        bg: '#000',
        animation: `${opacityUp} .5s linear forwards`,
        _after: {
          borderColor: 'gray.400'
        }
      }
    },
    checkboxOpacity: {
      checkwrap: {
        borderColor: '#000',
        opacity: 0.35
      },
      checkitem: {
        _after: {
          borderColor: '#000'
        }
      }
    },
    checkboxOpacityBlack: {
      checkwrap: {
        borderColor: '#000',
        opacity: 0.35
      },
      checkitem: {
        bg: '#000',
        _after: {
          borderColor: 'gray.400'
        }
      }
    }
  },
  defaultProps: {
    variant: 'checkboxBlack'
  }
};
